$( document ).ready(function() {
  $( ".js-spinner" ).spinner();
/*
  $( ".js-card-info" ).on( "swipeleft", swipeleftHandler  , $(this) );

  function swipeleftHandler( event , item){
    $( event.target ).addClass( "swiperight" );
    $(item).addClass('rotate-left').delay(700).fadeOut(1);
  }*/

  let countCard = $(".js-card-info").length,
      currentItem = 1;

  $(document).on('swipeleft', '.js-card-info', function(event){    
   
   
    if (currentItem == 3) {
     /* currentItem = 1;
      $(".js-card-info").removeClass("is-active").removeClass("rotate-left").show();
      $(".js-card-info").eq(0).addClass('is-active').show();;*/
      $(".js-card-info").eq(currentItem - 1).addClass('is-active');
    } else {
      $(this).addClass('rotate-left').fadeOut( "slow", function() {
        // Animation complete.
      });

      $(".js-card-info").eq(currentItem).addClass('is-active');

      currentItem = currentItem + 1;
    }
    
      
});

  $(".js-aside-control").on("click", function () {
    $(this).toggleClass("is-active");
    $(this).parents(".js-aside-item").find(".js-aside-body").slideToggle(300, function() {});
  });

  $(".js-in-basket").on("click", function () {
    $(this).toggleClass("is-in");
    return false;
  });

  $(".js-in-favorites").on("click", function () {
    $(this).toggleClass("is-active");
    return false;
  });

  if ($(window).width() < 650) {
      $(".js-sort-title").on("click", function () {
        $(this).toggleClass("is-active");
        $(this).next(".js-sort-body").toggleClass("is-active");
      });  

      $(document).mouseup( function(e){ // событие клика по веб-документу
        var div = $( ".js-sort" ); // тут указываем ID элемента
        if ( !div.is(e.target) // если клик был не по нашему блоку
            && div.has(e.target).length === 0 ) { // и не по его дочерним элементам
              $(".js-sort-title").removeClass("is-active");
              $(".js-sort-body").removeClass("is-active");
        }
      });
  }

  if ($(window).width() < 560) {
    $(".js-menu-bottom-item").on("click", function () {
      $(this).toggleClass("is-active");
  
      $(this).next(".js-menu-bottom-body").slideToggle(300, function() {});

      return false;
    });

  }



  $(window).scroll(function () {
    var scrollTop = $(window).scrollTop();

    if ($('.js-card-mcain').length > 0) {
      if (scrollTop > ($(".js-card-mcain").position().top)) {
          $(".js-card-info-fixed").addClass("is-active");
      } else {
          $(".js-card-info-fixed").removeClass("is-active");
      }
    }
  });



  $(".js-accordion-mobile").on("click", function () {
    $(".js-accordion-drop").slideToggle(300, function() {});
  });

  $(".js-accordion-top").on("click", function () {
    $(this).next(".js-accordion-text").slideToggle(300, function() {});
  });


  $(".js-catalog-mobile").on("click", function () {
    $(this).toggleClass("is-active");
    $(".js-mobile-catalog").toggleClass("is-open");
  });

    $(".js-mobile-menu-item").on("click", function () {
      $(this).toggleClass("is-active");

      $(this).next(".js-mobile-menu-drop").slideToggle(300, function() {
        // Animation complete.
      });

      return false;
    });;

    /**** step  ****/
    $(".js-step-name").on("click", function () {
      var tabNumber = $(this).data("number");

      $(this).toggleClass("is-active");

      $(".js-step-body[data-number=" + tabNumber + "]").slideToggle(300, function() {
        // Animation complete.
      });
    });
    /**** END step ****/



  $(".js-filter-item").on("click" , function(){
    $(".js-filter-item").removeClass("is-active");
    $(".js-filter-item-main").removeClass("is-active");
    $(this).addClass("is-active");
    $(this).parents(".js-filter-item-main").addClass("is-active");
  });
 
  $(document).mouseup( function(e){ // событие клика по веб-документу
		var div = $( ".js-filter-item-main" ); // тут указываем ID элемента
		if ( !div.is(e.target) // если клик был не по нашему блоку
		    && div.has(e.target).length === 0 ) { // и не по его дочерним элементам
          $(".js-filter-item").removeClass("is-active");
          $(".js-filter-item-main").removeClass("is-active");
		}
	});
 
 
 
 
 
 
  /*** HEADER ***/
  $(".js-step-hamburger").on("click" , function(){
    $(".js-aside-step").toggleClass("is-active");
    $("body").addClass("is-hidden");
  });

  $(".js-aside-step-close").on("click" , function(){
    $(".js-aside-step").removeClass("is-active");
    $("body").removeClass("is-hidden");
  })


  $(".js-hamburger").on("click" , function(){
    $(".js-menu-mobile").toggleClass("is-active");
  });
  
  /*$(".js-mobile-menu-close").on("click" , function(){
    $(".js-mobile-menu").removeClass("is-active");
  })*/
  /*** END HEADER ***/




  /*** SEARCH HEADER ***/
  $(".js-search-main-input").on('focus', function () {
      $(".js-search-main").addClass("is-active");
      $("header").addClass("is-active");
      $(".js-search-main-back").addClass("is-active");
      $(".js-header").addClass("is-active");
  });


  $(".js-search-main-back").on('click', function () {
    $(".js-search-main").removeClass("is-active");
    $(this).removeClass("is-active");
    $("header").removeClass("is-active");
    $(".js-search-main-mobile").removeClass("is-open");
    $(".js-header").removeClass("is-active");
  });

  $(".js-search-main-mobile-close").on('click', function () {
      $(".js-search-main-mobile").removeClass("is-open");
      $(".js-search-main-back").toggleClass("is-active");
      $(".js-search-main").toggleClass("is-active");
  });

  $(".js-hamburger-search").on('click', function () {
    $(this).toggleClass('is-active');
    $(".js-search-main-mobile").toggleClass("is-open");
    $(".js-search-main-back").toggleClass("is-active");
    $(".js-search-main").toggleClass("is-active");
  });

  $(".js-search-main-delete").on('click', function () {
    $(".js-search-main-input").val('');
    $(".js-search-main-delete").removeClass("is-active");
    $(".js-search-main-input").removeClass("is-active");
  });

  $(".js-search-main-input").on('input', function () {
      if ($(this).val().length > 2){
        $(".js-search-main-input").addClass("is-active");
        $(".js-search-main-delete").addClass("is-active");
      }
  });


   /*** END SEARCH HAEDER ***/
/*
  */


/*$(".js-card-info").on("swipeleft",function(){
  console.log("sdfsdfsdfsdf");
  $(this).addClass('rotate-right').delay(700).fadeOut(1);
  $('.buddy').find('.status').remove();
  $(this).append('<div class="status dislike">Dislike!</div>');

  if ( $(this).is(':last-child') ) {
   $('.buddy:nth-child(1)').removeClass ('rotate-left rotate-right').fadeIn(300);
    alert('OUPS');
   } else {
      $(this).next().removeClass('rotate-left rotate-right').fadeIn(400);
  } 
});*/
 

  $(".js-shop-item").on('click', function () {
    let shopNumber = $(this).data("item");

    console.log("shopNumber = " , shopNumber);

    $(".js-shop-contact").hide();
    $(".js-shop-contact[data-item=" + shopNumber + "]").show();
  });


      /*** FILE ***/

    $('.js-file').on('change', function () {
        var splittedFakePath = this.value.split('\\');

        console.log("splittedFakePath =", splittedFakePath[splittedFakePath.length - 1]);
        // $('div').text(splittedFakePath[splittedFakePath.length - 1]);

        $(".js-file-wrap").addClass("is-active");
        $(".js-file-name span").text(splittedFakePath[splittedFakePath.length - 1]);
    });
    /*** END FILE ***/

  /*MAP*/

  if ($('#map-delivery').length) {
    var init = function init() {
        var myMap = new ymaps.Map("map-delivery", {
            center: [56.176278, 44.166461],
            zoom: 10
        });

        myMap.controls.remove('zoomControl');
        myMap.behaviors.disable('scrollZoom');
        myMap.behaviors.disable('drag');
    };

    ymaps.ready(init);
}

  if ($('#shopmap').length) {
    var init = function init() {
        var myMap = new ymaps.Map("shopmap", {
            center: [56.176278, 44.166461],
            zoom: 10
        });

        var myPlacemark = new ymaps.Placemark(myMap.getCenter(), {}, {
            iconLayout: 'default#image',
            iconImageHref: './images/common/Pin.svg',
            iconImageSize: [45, 64],
            iconImageOffset: [-24, -64]
        });

        var myPlacemark2 = new ymaps.Placemark([56.358457, 44.055897], {}, {
            iconLayout: 'default#image',
            iconImageHref: './images/common/Pin.svg',
            iconImageSize: [45, 64],
            iconImageOffset: [-24, -64]
        });

        var myPlacemark3 = new ymaps.Placemark([56.247421, 43.403352], {}, {
          iconLayout: 'default#image',
          iconImageHref: './images/common/Pin.svg',
          iconImageSize: [45, 64],
          iconImageOffset: [-24, -64]
      });

      var myPlacemark4 = new ymaps.Placemark([56.241484, 43.976423], {}, {
        iconLayout: 'default#image',
        iconImageHref: './images/common/Pin.svg',
        iconImageSize: [45, 64],
        iconImageOffset: [-24, -64]
    });

      

        myMap.controls.remove('zoomControl');
        myMap.behaviors.disable('scrollZoom');
        myMap.behaviors.disable('drag');
        myMap.geoObjects.add(myPlacemark);
        myMap.geoObjects.add(myPlacemark2);
        myMap.geoObjects.add(myPlacemark3);
        myMap.geoObjects.add(myPlacemark4);
    };

    ymaps.ready(init);
}

  if ($('#map-order').length) {
    var init = function init() {
        var myMap = new ymaps.Map("map-order", {
            center: [56.176278, 44.166461],
            zoom: 16
        });

        var myPlacemark = new ymaps.Placemark(myMap.getCenter(), {}, {
            iconLayout: 'default#image',
            iconImageHref: './images/common/marker.png',
            iconImageSize: [50, 60],
            iconImageOffset: [-25, -60]
        });

        myMap.controls.remove('zoomControl');
        myMap.behaviors.disable('scrollZoom');
        myMap.behaviors.disable('drag');
        myMap.geoObjects.add(myPlacemark);
    };

    ymaps.ready(init);
}


  /*** IZB POPUP ***/ 
  $(".js-delete-izb").on("click", function () {
    $(this).toggleClass("is-active");
    $(this).parents(".js-delete-block").find(".btn").toggleClass("is-delete js-delete-item")
  });

  /*FILTER*/
  $(".js-filter-top").on("click", function () {
    $(this).toggleClass("is-active");
    $(this).next(".js-filter-body").slideToggle(300, function() {
      // Animation complete.
    });
  });

  /*** TOOLTIP ***/
  $(".js-tooltip-icon").on("click", function () {
    $(this).parents(".js-tooltip").toggleClass("is-active");
  });

  $(".js-tooltip-close").on("click", function () {
    $(this).parents(".js-tooltip").toggleClass("is-active");
  });

  $(document).mouseup( function(e){ 
		var div = $( ".js-tooltip" ); 
		if ( !div.is(e.target) 
		    && div.has(e.target).length === 0 ) { 
          $(".js-tooltip").removeClass("is-active");
		}
	});
  /*** END TOOLTIP ***/

  /*** FORM ***/
  function formatState (state) {
    if (!state.id) {
      return state.text;
    }
    var baseUrl = "/images/message";
    var $state = $(
      '<span><img src="' + baseUrl + '/' + state.element.value.toLowerCase() + '.jpg" class="img-flag" /> ' + state.text + '</span>'
    );
    return $state;
  };

  $(".js-message").select2({
    templateResult: formatState,
    minimumResultsForSearch: -1
  });

  $(".js-select-default").select2({
    minimumResultsForSearch: -1
  });

  $(".js-select-card").select2({
    dropdownCssClass: "select-default__container",
    minimumResultsForSearch: -1
  });

  function formatItem (state) {
    if (!state.id) {
      return state.text;
    }

    console.log("state" , state);
    var baseUrl = "/images/cosntructor/color";
    var $state = $(
      '<div  class="select-item">\
        <span class="select-item__color" style="background-image: url(' + baseUrl + '/' + state.title + '.jpg)" /> ' + state.text + '</span></div>'
    );
    return $state;
  };

  $(".js-form-select-color").select2({
    dropdownCssClass: "select-default__container",
    templateResult: formatItem,
    minimumResultsForSearch: -1
    
  });

  /*** END FORM ***/



  $(".js-history-more").on("click", function () {
    $(this).toggleClass("is-active");
    if ($(this).hasClass("is-active")) {
      $(this).find("span").text("Свернуть"); 
    } else {
      $(this).find("span").text("Подробнее");
    }

    $(this).parents(".js-history").find(".js-history-wrap").slideToggle(300, function() {
      // Animation complete.
    });
    
  });

	/*** POPUP ***/

	$(".js-popup-close").on("click", function () {
		var popup = $(this).parents(".js-popup");

		$(popup).removeClass("is-active");
		$("body").removeClass("is-hidden");
	});

    $(document).on("click", function (e) {
		var popup = $(".js-popup");
		if (popup.is(e.target)) {
			$(popup).removeClass("is-active");
			$("body").removeClass("is-hidden");
		}
    });

    $(".js-popup-open").on("click", function () {
		var name = $(this).data("popup");
    $(".js-popup").removeClass("is-active");

		$(".js-popup[data-popup='" + name + "']").addClass("is-active");
		$("body").addClass("is-hidden");

		return false;
	});

	/*** END POPUP ***/
  


    $(".js-item-scroll").mCustomScrollbar({
      axis:"y" 
    });

    $(".js-popup-scroll").mCustomScrollbar({
      axis:"y" 
    });

    $(".js-item-wrap").mCustomScrollbar({
      axis:"y" 
    });

    $(".js-scroll").mCustomScrollbar({
      axis:"x" 
    });

    function myCustomFn(el){
      console.log(el.mcs.leftPct);
  }

    $(".js-scroll-item").mCustomScrollbar({
      scrollButtons:{enable:true,scrollType:"stepped"},
      keyboard:{scrollType:"stepped"},
      mouseWheel:{scrollAmount:188,normalizeDelta:true},
      theme:"rounded-dark",
      autoExpandScrollbar:true,
      snapAmount:0,
      snapOffset:10,
      axis:"x",
      autoDraggerLength:false,
      autoExpandScrollbar:true,
      callbacks:{
        onScroll: function(){
          myCustomFn(this);
          console.log("Content scrolled...");
        }
    } 
    });

    /**** TAB ****/
    $(".js-tab-item").on("click", function () {
      var tabNumber = $(this).data("tab");

      $(".js-tab-item").removeClass("is-active");
      $(this).addClass("is-active");

      $(".js-tab-block").removeClass("is-active");
      $(".js-tab-block[data-tab=" + tabNumber + "]").addClass("is-active");
    });
    /**** END TAB ****/


    /*** SLIDER ***/
    if ($('.js-item-slider').length > 0) {
      $(".js-item-slider").slick({
        slidesToShow: 1,
        arrows: true,
        dots: true,
        infinite: false,
        fade: true,
        cssEase: 'linear'
      });
    }

    if ($('.js-ifilter').length > 0) {
      $(".js-ifilter").slick({
        slidesToShow: 6,
        arrows: false,
        dots: false,
        infinite: false,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4,
              dots: true,
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
              dots: true,
            }
          },
          {
            breakpoint: 560,
            settings: {
              slidesToShow: 2,
              dots: true,
            }
          }
        ]
      })
  
    }
  
    if ($('.js-slider-catalog').length > 0) {
      $('.js-slider-catalog').on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
          //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
          var i = (currentSlide ? currentSlide : 0) + 1;

          $(".current-number").text(i);
          $(".common-number").text(slick.slideCount);
      });


      $('.js-slider-catalog').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        infinite: false,
        dots: false,
        prevArrow: "<button type='button' class='slick-prev pull-left'>\
        <svg  width='13' height='10' viewBox='0 0 13 10' class='svg-icon'><use xlink:href='#svg-slick-long'></use></svg>\
        </button>",
        nextArrow: "<button type='button' class='slick-next pull-right'>\
        <svg  width='13' height='10' viewBox='0 0 13 10' class='svg-icon'><use xlink:href='#svg-slick-long'></use></svg>\
        </button>"
      });
  }

  if ($('.js-slider-catalog').length > 0) {
    $('.js-slider-catalog').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      asNavFor: '.js-slider-nav',
      responsive: [
        {
          breakpoint: 560,
          settings: {
            dots: true
          }
        }
      ]
    });
  }

  if ($('.js-slider-nav').length > 0) {

    $('.js-slider-nav').slick({
      infinite: false,
      slidesToShow: 5,
      slidesToScroll: 1,
      variableWidth: true,
      asNavFor: '.js-slider-for',
      dots: false,
      arrows: true,
      focusOnSelect: true,
      prevArrow: "<button type='button' class='slick-prev pull-left'>\
      <svg  width='19' height='63' viewBox='0 0 19 63' class='svg-icon'><use xlink:href='#svg-slick'></use></svg>\
      </button>",
      nextArrow: "<button type='button' class='slick-next pull-right'>\
      <svg  width='19' height='63' viewBox='0 0 19 63' class='svg-icon'><use xlink:href='#svg-slick'></use></svg>\
      </button>"
    });
  }
    
  if ($('.js-slider-img').length > 0) {

    $(".js-slider-img").slick({
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: false
    });
  }

  


    function prettify(num) {
      var n = num.toString();
      return n.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + ' ');
  }

  
    /*$(".js-filter-item-main input").on("click", function () {
      let parents = $(this).parents(".js-filter-item-main"),
          string = "";

       $(parents).find("input").each(function( index , elem ) {
          if ($(elem).attr("checked") != 'checked') { 
              console
          };
       });
      
        $(this).parents(".js-filter-item-main").find(".js-filter-item").removeClass("is-full");


    });*/

    $(".js-filter-item-range-close").on("click", function () {
      $(this).parents(".js-filter-item-main").find(".js-filter-item").removeClass("is-full");
    });

    $(".js-filter-item-main input").on("click", function () {
      $(this).parents(".js-filter-item-main").find(".js-filter-item").removeClass("is-full");
    });

    $(".js-range").slider({
      range: true,
      min: 0,
      max: 9999,
      values: [ 0, 9999 ],
      slide: function( event, ui ) {
        $(this).parents(".js-range-main").find(".js-range-min").html(prettify(ui.values[ 0 ]));
        $(this).parents(".js-range-main").find(".js-range-max").html(prettify(ui.values[ 1 ]));

        $(this).parents(".js-filter-item-main").find(".js-filter-item span").text(ui.values[ 0 ] + "-" + ui.values[ 1 ] );
        $(this).parents(".js-filter-item-main").find(".js-filter-item").addClass("is-full");
      }
    });

    /*** SLIDER ***/

  


    /*** TAB ****/

    $(".js-tab-link").on("click", function () {
      var tabNumber = $(this).data("tab"),
          parents = $(this).parents(".js-tab");

      $(parents).find(".js-tab-link").removeClass("is-active");
      $(this).addClass("is-active");


      $(parents).find(".js-tab-block").removeClass("is-active");
      $(parents).find(".js-tab-block[data-tab=" + tabNumber + "]").addClass("is-active");
      
      $(parents).find(".js-slider-img").slick('setPosition');
    });

    /***  END TAB ****/



























    



/*
  $(document).click(function (e) {
    var div = $(".js-mobile-menu");
    if (!div.is(e.target) && div.has(e.target).length === 0) {
        $(".js-mobile-menu").removeClass("is-open");
    }
});*/

    /*** ANCHOR ***/
    $('.js-anchor-item').on("click", function () {
      $(".js-mobile-menu").removeClass("is-open");

      var anchor = $(this).data('anchor');

      $('html, body').animate({
          scrollTop: $('.js-anchor-block[data-anchor=' + anchor + ']').offset().top - 60
      }, 600);
      return false;
    });

    $(window).scroll(function () {
      var scrollTop = $(window).scrollTop();

      $(".js-anchor-block").each(function (index, elem) {
          var offsetItem = $(elem).offset().top,
              dataItem = $(this).data("anchor");

              console.log("dataItem = " , dataItem);

          if (offsetItem - 120 < scrollTop) {
              $(".js-anchor-item").removeClass("is-active");
              $(".js-anchor-item-wrap").removeClass("is-active");
              $(".js-anchor-item[data-anchor=" + dataItem + "]").addClass("is-active");
              $(".js-anchor-item[data-anchor=" + dataItem + "]").parents(".js-anchor-item-wrap").addClass("is-active");
          }
      });
    });
    /*** END ANCHOR ***/


    

    /*** ACCORDION ***/
    $(".js-acc-top").on("click", function () {
      $(this).toggleClass("is-active");
      $(this).find(".plus-minus-toggle").toggleClass("collapsed");
      $(this).next(".js-acc-body").slideToggle(300, function () {});
    });

    /*** END ACCORDION ***/

  /*** SLIDER ***/  
  if ($('.js-item-slider').length > 0) {
  var $status = $('.pagingInfo');

  var $slickElement = $('.js-people-item');

  $slickElement.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
    //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
    var i = (currentSlide ? currentSlide : 0) + 1;
    $(".pagingInfo").text(i);
    $(".slideshow").text(slick.slideCount);
  });


  $(".js-people-item").slick({
    slidesToShow: 1,
    arrows: true,
    dots: false,
    infinite: false,
    fade: true,
    cssEase: 'linear',
    prevArrow: '<button type="button" class="slick-prev">\
    <svg width="16" height="30" viewBox="0 0 16 30" fill="none" xmlns="http://www.w3.org/2000/svg">\
      <path d="M15.1494 29L2 15L15.1494 0.999999" stroke-width="2"/>\
    </svg>\
        </button>',
    nextArrow: '<button type="button" class="slick-next">\
    <svg width="16" height="30" viewBox="0 0 16 30" fill="none" xmlns="http://www.w3.org/2000/svg">\
    <path d="M0.850572 0.999999L14 15L0.85057 29"  stroke-width="2"/>\
    </svg>\
    </button>',
  
    responsive: [
      {
        breakpoint: 1100,
        settings: {
            
        }
      }
    ]
  }); }

  $(".js-about-slider").slick({
    slidesToShow: 1,
    arrows: true,
    dots: true,
    infinite: false,
    fade: true,
    cssEase: 'linear',
    prevArrow: '<button type="button" class="slick-prev">\
    <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">\
<path d="M0.93934 10.9393C0.353553 11.5251 0.353553 12.4749 0.93934 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97918 12.6066 1.3934C12.0208 0.807611 11.0711 0.807611 10.4853 1.3934L0.93934 10.9393ZM3 10.5L2 10.5L2 13.5L3 13.5L3 10.5Z" fill="#E7C89A"/>\
</svg>\
        </button>',
    nextArrow: '<button type="button" class="slick-next">\
    <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">\
    <path d="M13.0607 10.9393C13.6464 11.5251 13.6464 12.4749 13.0607 13.0607L3.51472 22.6066C2.92893 23.1924 1.97918 23.1924 1.3934 22.6066C0.807611 22.0208 0.807611 21.0711 1.3934 20.4853L9.87868 12L1.3934 3.51472C0.807612 2.92893 0.807612 1.97918 1.3934 1.3934C1.97919 0.807611 2.92893 0.807611 3.51472 1.3934L13.0607 10.9393ZM11 10.5L12 10.5L12 13.5L11 13.5L11 10.5Z" fill="#E7C89A"/>\
    </svg>\
    </button>',
  
    responsive: [
      {
        breakpoint: 1100,
        settings: {
            slidesToShow: 1,
            dots: true,
        }
      }
    ]
  });

  /*** END SLIDER ***/  





  $(".js-form-label").on("click", function () {
    $(this).parents(".js-form-field").addClass("is-active");
    $(this).parents(".js-form-field").find(".js-form-input").focus();
});

$(".js-form-input").on("blur", function () {
    if ($(this).val().length >= 1) {} else {
        $(this).parents(".js-form-field").removeClass("is-active");
    }
});







$(".js-phone").mask("+7 (999) - 999 - 99 - 99");

$(".js-submit").on("click" , function(){
  $(this).parents(".js-form").find(".js-form-ok").addClass("is-active");
  
  setTimeout(function () {
    $(".js-form-ok").removeClass("is-active");
  }, 1000);
  return false;
})



});